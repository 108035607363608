import "../jquery-plugins/jquery.typewatch";
import get_search_history_markup from './homepage-search-box/history-markup'
import { search_history } from './homepage-search-box/history-markup'
import get_search_suggest_markup from './homepage-search-box/suggest-markup'

export default function init() {
    /*
        Watch for clicks in search category selectors and trigger update
        for search result count
     */
    $(document).on("click", ".js__searchbox-category-selection .home-sbox-category-selection__item", function () {
        $(this)
            .addClass("active")
            .siblings(".home-sbox-category-selection__item")
            .removeClass("active");

        $("#homepageSearchBoxCategoryField")
            .val($(this).data("category"))
            .trigger("change");
    });

    init_search_history()

    $("#homepageSearchBoxSearchFieldFulltextInput")
        .on('focus', show_dropdown)
        // .on('blur', window.setTimeout(hide_dropdown, 250))
        .typeWatch({
            callback: search,
            wait: 250,
            highlight: true, // Highlights the element when it receives focus
            captureLength: 2 // Minimum # of characters necessary to fire the callback
        });

    $(document).on('change', '#homepageSearchBoxForm .WatchedField', search);

    $('.search-history-trigger-submit').on('click', function (event) {
        $(event.target).parents('form').first().trigger('submit')
    });
}

function search() {
    const $form = $("#homepageSearchBoxForm");
    let $btn = $("#homepageSearchBoxSubmitButton");
    $btn.html($btn.data('placeholder-searching'));

    $.getJSON(
        $form.attr("action") + ".json?" + $form.serialize(),
        function (data) {
            let resultData = data.data;
            render_search_button_text($btn, resultData)
            render_dropdown_contents(resultData, null)
        }
    );
}

function init_event_handler_suggest_items() {
    $('.search-history-loggable').on('click', function (event) {
        event.preventDefault()
        event.stopPropagation()

        let log_data = {
            query: $('#homepageSearchBoxSearchFieldFulltextInput').val()
        }
        log_data[mtbn.bikemarkt.csrf.name] = mtbn.bikemarkt.csrf.token

        $.post('/api/search/log', log_data, function () {
            window.document.location.href = $(event.target).parents('a').first().attr('href')
        })

        return false
    })
}

function init_event_handler_history_items() {
    $('.search-history-delete').on('click', function (event) {
        event.stopPropagation()

        let request_data = {
            id: $(event.target).parents('.search-history-row').data('itemId')
        }
        request_data[mtbn.bikemarkt.csrf.name] = mtbn.bikemarkt.csrf.token

        $.post('/api/search/delete-history-item', request_data, function () {
            $(event.target).parents('.search-history-row').fadeOut();
        })
    })
}

function init_search_history() {
    $.getJSON('/api/search/history.json', function (data) {
        render_dropdown_contents(null, data.data)
    })
}

function render_search_button_text($button, data) {
    if (data === null || typeof data.countTotal === "undefined") {
        $button.text($button.data("value-default"))

        return
    }

    $button.text($button.data("value-results").replace(/{:count}/, data.countTotal))
}

function render_dropdown_contents(suggest_data, history_data) {
    let $dropdown = $('<div><div id="search-dropdown-suggest"></div><div id="search-dropdown-history"></div></div>')
    $('.search-dropdown').html($dropdown.html()).append('<div class="search-dropdown-close tappable">schließen</div>')

    if (suggest_data !== null) {
        $('#search-dropdown-suggest').html(get_search_suggest_markup(suggest_data))
        init_event_handler_suggest_items()
    }
    if (history_data !== null || search_history !== '') {
        $('#search-dropdown-history').html(get_search_history_markup(history_data))
        init_event_handler_history_items()
    }

    $('.search-dropdown-close').on('click', hide_dropdown)
}

function show_dropdown() {
    $('.search-dropdown').removeClass('hidden')
}

function hide_dropdown() {
    $('.search-dropdown').addClass('hidden')
}
