export default function init() {
    init_close_button()
}

function init_close_button() {
    $('.mtbn-sell-popover__close-trigger').on('click', function (event) {
        $(event.target).parents('.mtbn-sell-popover').fadeOut()
        let post_data = {
            key: 'dismiss_sell_popover',
            value: '1'
        }
        post_data[mtbn.bikemarkt.csrf.name] = mtbn.bikemarkt.csrf.token

        $.post(
            '/api/user/meta',
            post_data
        )
    })
}
