export default function (data) {
    let markup = ''
    const limit = 7
    let counter = 0
    const results = data.results

    for (const index in results) {
        markup += '<a href="' + results[index].url + '" class="search-history-loggable">'
        markup += '<div class="search-history-row">'
        markup += '<div class="search-history-title">' + results[index].title + '</div>'
        markup += '</div>'
        markup += '</a>'
        counter++
        if (counter >= limit) {
            break;
        }
    }

    markup += '<button type="submit" class="btn btn-secondary">' + data.countTotal + ' Angebote anzeigen</button>'

    return markup
}
