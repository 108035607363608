import "chosen-js";

export default function init() {
    init_brand_field()
}

function init_brand_field() {
    $(".chosen-enhanced-field").chosen(
        {
            allow_single_deselect: true,
            disable_search_threshold: 10,
        }
    );
    $(".chosen-container").each(function(i, elem) {
        if ($(elem).width() === 0) {
            $(elem).width("100%");
        }
    });
}
