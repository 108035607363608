export let search_history = ''

export default function (data) {
    if (search_history !== '') {
        return search_history
    }

    if (data.length === 0) {
        return ''
    }

    let markup = '<div class="search-history-row search-history-row--header">Dein Suchverlauf</div>'

    for (const index in data) {
        let title = '<a href="' + data[index].url + '"><div class="search-history-title">' + data[index].query + '</div></a>'

        let results = ''
        // if (data[index].hasOwnProperty('results') && data[index].results > 0) {
        //     results = '<div class="search-history-suffix-item search-history-count-results text-center">' + data[index].results + 'x</div>'
        // }

        let results_new = ''
        if (data[index].hasOwnProperty('results_new') && data[index].results_new > 0) {
            results_new = '<div class="search-history-suffix-item search-history-count-results--new text-center">+' + data[index].results_new + '</div>'
        }

        let delete_icon = '<div class="search-history-delete"><i class="mtb-icon-cancel tappable"></i></div>'
        let suffix = '<div class="search-history-suffix">' + delete_icon + results + results_new + '</div>'

        markup = markup + '<div class="search-history-row" data-item-id="' + data[index].id + '">' + title + suffix + '</div>'
    }

    search_history = markup

    return search_history
}
